import {
  Button,
  Form,
  Header,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  EditContainerType,
  getEditComponentProps,
} from "internal/programs/migration-acceleration-program/2024/fund-request/components/edit/EditContainerFactory/EditContainerComponent";
import React, { useState } from "react";
import { IEditContainerProps } from "internal/programs/migration-acceleration-program/2024/fund-request/types/SchemaType";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { useHistory } from "react-router-dom";
import { getFundRequestId } from "internal/util/helper";
import { RouteOrigin } from "shared/util/constants/RouteState";
import {
  Error as ResultError,
  handleErrors,
  Result,
  ResultErrorType,
} from "shared/util/api/util";
import { GENERIC_ERROR_MESSAGE } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";

const EditContainerForm = ({
  editContainerType,
  claimId,
}: {
  editContainerType: EditContainerType;
  claimId?: string;
}) => {
  const history = useHistory();
  const [data, setData] = useState<IGenericObject>({});
  const [errors, setErrors] = useState<IGenericObject>({});
  const [formError, setFormError] = useState<string>("");

  const onChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | boolean | undefined;
  }) => {
    if (formError) setFormError("");
    if (errors[key]) {
      setErrors({ ...errors, [key]: "" });
    }
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const onError = ({ key, value }: { key: string; value: string }) => {
    setErrors((prevErrors) => ({ ...prevErrors, [key]: value }));
  };

  const editComponentProps = getEditComponentProps(editContainerType);
  const EditContainerComponent = editComponentProps.content;
  const containerProps: IEditContainerProps = {
    title: editComponentProps.title,
    adapt: editComponentProps.adapt,
    data,
    errors,
    onError,
    onChange,
    setData,
    setErrors,
    formError,
    setFormError,
    claimId: claimId,
  };

  return (
    <Form
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Edit {editContainerType}
        </Header>
      }
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            formAction="none"
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          {editComponentProps.onSaveDraft && (
            <Button
              onClick={async () => {
                try {
                  const save = editComponentProps.onSaveDraft;
                  const result = save && (await save(data, claimId));
                  if (result && !result.success) {
                    const errorResult = result as Result<
                      ResultError<ResultErrorType>
                    >;
                    handleErrors({
                      result: errorResult,
                      setErrors: setErrors,
                      setFormError: setFormError,
                    });
                    return;
                  }
                  history.push(`/fund-requests/${getFundRequestId()}/details`, {
                    origin: RouteOrigin.Edit,
                    message: "Action successful",
                  });
                } catch (e) {
                  setFormError(GENERIC_ERROR_MESSAGE);
                  console.log(e);
                }
              }}
            >
              Save draft
            </Button>
          )}
          <Button
            variant="primary"
            onClick={async () => {
              try {
                const submit = editComponentProps.onSubmit;
                const result = submit && (await submit(data, claimId));
                if (!!result && result.success) {
                  //manage state of unchangedAwsAccountId, this helps track if the awsAccountId is editable in cash claims
                  data.unChangedAwsAccountId = data.awsAccountId;
                }
                if (result && !result.success) {
                  const errorResult = result as Result<
                    ResultError<ResultErrorType>
                  >;
                  handleErrors({
                    result: errorResult,
                    setErrors: setErrors,
                    setFormError: setFormError,
                  });
                  return;
                }
                history.push(`/fund-requests/${getFundRequestId()}/details`, {
                  origin: RouteOrigin.Edit,
                  message: "Action successful",
                });
              } catch (e) {
                setFormError(GENERIC_ERROR_MESSAGE);
                console.log(e);
              }
            }}
          >
            Save
          </Button>
        </SpaceBetween>
      }
    >
      {/*@ts-expect-error @typescript-eslint/ban-ts-comment*/}
      <EditContainerComponent {...containerProps}></EditContainerComponent>
    </Form>
  );
};

export default EditContainerForm;
